import { atom } from 'jotai';
import { atomWithQuery } from 'jotai-tanstack-query';

import { ApiUrl } from '@/constants';
import { fetchJsonApi } from '@/helpers/api';
import { fileService, PdfOcrData } from '@/services';
import { FileInfoV2 } from '@/services/SearchService';

export const documentDetailIdAtom = atom('');
export const documentDetailTagsAtom = atom<string[]>([]);

export const documentDetailAtom = atomWithQuery((get) => ({
  enabled: get(documentDetailIdAtom) !== '',
  queryFn: async (): Promise<{
    file: FileInfoV2;
    fileUrl: string;
    ocrData?: PdfOcrData;
  }> => {
    try {
      const fileMetadata = await fetchJsonApi(
        `/search/metadata/${get(documentDetailIdAtom)}`,
      );

      if (!fileMetadata.uuid) {
        return Promise.reject('File not found');
      }

      const { data: file } = await fetchJsonApi(
        `${ApiUrl.FILE_METADATA.replace('{uuid}', fileMetadata.uuid)}`,
      );

      if (
        file.is_ocr_content_extracted &&
        get(documentDetailTagsAtom).length > 0
      ) {
        const [, readFileAsync] = fileService.read(
          file,
          get(documentDetailTagsAtom),
          true,
        );

        const [fileBlob, ocrData] = await readFileAsync;

        if (fileBlob) {
          const blobUrl = window.URL.createObjectURL(new Blob([fileBlob]));

          return {
            file: { ...file, ...fileMetadata },
            fileUrl: blobUrl,
            ocrData,
          };
        }
      } else {
        const fileUrl = await fileService.readPdfWithoutOCR(file);

        if (fileUrl) {
          return { file: { ...file, ...fileMetadata }, fileUrl };
        }
      }

      return Promise.reject('File not found');
    } catch (error) {
      return Promise.reject(error);
    }
  },
  queryKey: ['documentDetail', get(documentDetailIdAtom)],
  retry: false,
}));
