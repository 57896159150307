import { HTMLAttributes, ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';

import logoDna from '../../assets/img/logo-dna.svg';
import logoDnaFull from '../../assets/img/logo-dna-full.svg';
import { AppConfig } from '../../constants';

interface LogoProps extends HTMLAttributes<HTMLDivElement> {
  full?: boolean;
  small?: boolean;
}

export function Logo({
  full = false,
  small = false,
  ...props
}: LogoProps): ReactElement {
  return (
    <div {...props}>
      {full ? (
        <img
          alt={AppConfig.NAME}
          className={twMerge(logoDnaFull, small ? 'h-[19px]' : 'h-[48px]')}
          src={logoDnaFull}
        />
      ) : (
        <img
          alt={AppConfig.NAME}
          className={twMerge(logoDna, 'h-[32px]')}
          src={logoDna}
        />
      )}
    </div>
  );
}
