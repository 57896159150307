import 'react-input-range/lib/css/index.css';

import { isUndefined } from 'lodash';
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import InputRange from 'react-input-range';

import { AddIcon, RemoveIcon } from '../icons';

interface NumericInputStandaloneProps {
  defaultValue: number;
  formatter?: (value: number) => string;
  max: number;
  min: number;
  onChange?: (value: number) => void;
  slideStep?: number;
  step?: number;
  value?: number;
}

export const NumericInputStandalone = ({
  defaultValue,
  formatter,
  max,
  min,
  onChange,
  slideStep = 1,
  step = 1,
  value,
}: NumericInputStandaloneProps): ReactElement => {
  const [currentValue, setCurrentValue] = useState<number | undefined>(
    isUndefined(value) ? defaultValue : value,
  );
  const rendered = useRef(false);
  const formatLabel: string = useMemo(
    () => (formatter ? formatter(currentValue || 0) : ''),
    [formatter, currentValue],
  );

  const setNextValue = useCallback(
    (step: number) => {
      setCurrentValue((prev = 0) => {
        const mod = prev % step;
        const change = mod === 0 ? step : step > 0 ? step - mod : -mod;
        let nextValue = prev + change;

        if (nextValue > max) {
          if (change > 0) {
            return prev;
          }
          nextValue = max;
        } else if (nextValue < min) {
          if (change < 0) {
            return prev;
          }
          nextValue = min;
        }

        onChange &&
          setTimeout(() => {
            onChange(nextValue);
          }, 0);

        return nextValue;
      });
    },
    [min, max, onChange],
  );

  const increase = useCallback(() => {
    setNextValue(step);
  }, [step, setNextValue]);

  const decrease = useCallback(() => {
    setNextValue(step * -1);
  }, [step, setNextValue]);

  const onSlide = useCallback(
    (newValue) => {
      setCurrentValue(newValue);
      onChange && onChange(newValue);
    },
    [onChange],
  );

  useEffect(() => {
    rendered.current === true && setCurrentValue(value);
  }, [value]);

  useEffect(() => {
    rendered.current = true;
  }, []);

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
      }}
    >
      <RemoveIcon className={'ic'} onClick={decrease} />

      <div className="range-slider">
        <InputRange
          formatLabel={() => formatLabel}
          maxValue={max}
          minValue={min}
          onChange={onSlide}
          step={slideStep}
          value={Math.max(min, Math.min(max, currentValue as number))}
        />
      </div>

      <AddIcon className={'ic'} onClick={increase} />
    </div>
  );
};
