import { numberToHexColor } from '../helpers/utils';

export const PageUrl = {
  DETAIL: '/detail',
  DOCUMENT: '/document',
  HOME: '/',
  LOGIN: '/',
  LOGOUT: '/logout',
  PROFILE: '/profile',
  RESULTS: '/results',
  ROLES: '/roles',
  USERS: '/users',
};

export enum VIEW_OPTION {
  TABLE = 'table',
  THUMBNAIL = 'thumbnail',
}

export type TypeView = VIEW_OPTION.TABLE | VIEW_OPTION.THUMBNAIL;

export const PageUrlParam = {
  DOCUMENT_NUMBER: `document_number`,
  LIMIT: 'limit',
  OFFSET: 'offset',
  PREVIEW: `preview`,
  PROJECT_CODES: 'project_codes',
  QUERIES: 'queries',
  QUERY: 'q',
  REVISION: `revision`,
  UUID: `uuid`,
  VIEW_OPTION: `view`,
};

export const FitView = {
  HEIGHT: 'page-height',
  ORIGIN: 'page-actual',
  WIDTH: 'page-width',
};

export const SearchBoxView = {
  ID: 'search-tag',
  KEYWORD: 'fulltext',
  MAX_LENGTH: 1024,
  MAX_LENGTH_PER_TAG: 50,
  MAX_NUMBER_TAG: 50,
  MIN_LENGTH: 2,
} as const;

export const SearchTypes = {
  AND_SEARCH: 1,
  OR_SEARCH: 0,
};

export enum DocumentType {
  document = 'document',
}

export enum ITEM_TYPE {
  allType = 'all-type',
  equipment = 'equipment',
}

export const colors: {
  [key: number]: { alpha: number; hex: number; rgba: string };
} = {
  0: {
    alpha: 0.75,
    hex: 0xff4747,
    rgba: 'rgba(255, 71, 71, 0.75)',
  },
  1: {
    alpha: 0.75,
    hex: 0xffbf47,
    rgba: 'rgba(255, 191, 71, 0.75)',
  },
  2: {
    alpha: 0.75,
    hex: 0xf651a6,
    rgba: 'rgba(246, 81, 166, 0.75)',
  },
  3: {
    alpha: 0.75,
    hex: 0x47ff47,
    rgba: 'rgba(71, 255, 71, 0.75)',
  },
  4: {
    alpha: 0.75,
    hex: 0x4747ff,
    rgba: 'rgba(71, 71, 255, 0.75)',
  },
  5: {
    alpha: 0.75,
    hex: 0xb247ff,
    rgba: 'rgba(178, 71, 255, 0.75)',
  },
  6: {
    alpha: 0.75,
    hex: 0xff47ff,
    rgba: 'rgba(255, 71, 255, 0.75)',
  },
  7: {
    alpha: 0.75,
    hex: 0x96aac5,
    rgba: 'rgba(150, 170, 197, 0.75)',
  },
  8: {
    alpha: 0.75,
    hex: 0x47eaff,
    rgba: 'rgba(71, 234, 255, 0.75)',
  },
  9: {
    alpha: 0.75,
    hex: 0xb78f9a,
    rgba: 'rgba(183, 143, 154, 0.75)',
  },
  10: {
    alpha: 0.75,
    hex: 0xb9f651,
    rgba: 'rgba(185, 246, 81, 0.75)',
  },
  11: {
    alpha: 0.75,
    hex: 0xffe047,
    rgba: 'rgba(255, 224, 71, 0.75)',
  },
  12: {
    alpha: 0.75,
    hex: 0x4c4c4c,
    rgba: 'rgba(76, 76, 76, 0.75)',
  },
  13: {
    alpha: 0.75,
    hex: 0xf50000,
    rgba: 'rgba(245, 0, 0, 0.75)',
  },
  14: {
    alpha: 0.75,
    hex: 0xa36a00,
    rgba: 'rgba(163, 106, 0, 0.75)',
  },
  15: {
    alpha: 0.75,
    hex: 0xf5cc00,
    rgba: 'rgba(245, 204, 0, 0.75)',
  },
  16: {
    alpha: 0.75,
    hex: 0x00f500,
    rgba: 'rgba(0, 245, 0, 0.75)',
  },
  17: {
    alpha: 0.75,
    hex: 0x0000f5,
    rgba: 'rgba(0, 0, 245, 0.75)',
  },
  18: {
    alpha: 0.75,
    hex: 0x8f00f5,
    rgba: 'rgba(143, 0, 245, 0.75)',
  },
  19: {
    alpha: 0.75,
    hex: 0xf500f5,
    rgba: 'rgba(245, 0, 245, 0.75)',
  },
  20: {
    alpha: 0.75,
    hex: 0x6180a8,
    rgba: 'rgba(97, 128, 168, 0.75)',
  },
  21: {
    alpha: 0.75,
    hex: 0x00d8f5,
    rgba: 'rgba(0, 216, 245, 0.75)',
  },
  22: {
    alpha: 0.75,
    hex: 0x955f6d,
    rgba: 'rgba(149, 95, 109, 0.75)',
  },
  23: {
    alpha: 0.75,
    hex: 0x98e90c,
    rgba: 'rgba(152, 233, 12, 0.75)',
  },
  24: {
    alpha: 0.75,
    hex: 0xe90c7e,
    rgba: 'rgba(233, 12, 126, 0.75)',
  },
  25: {
    alpha: 0.75,
    hex: 0xa30000,
    rgba: 'rgba(163, 0, 0, 0.75)',
  },
  26: {
    alpha: 0.75,
    hex: 0xa36a00,
    rgba: 'rgba(163, 106, 0, 0.75)',
  },
  27: {
    alpha: 0.75,
    hex: 0xa38800,
    rgba: 'rgba(163, 136, 0, 0.75)',
  },
  28: {
    alpha: 0.75,
    hex: 0x00a300,
    rgba: 'rgba(0, 163, 0, 0.75)',
  },
  29: {
    alpha: 0.75,
    hex: 0x0000a3,
    rgba: 'rgba(0, 0, 163, 0.75)',
  },
  30: {
    alpha: 0.75,
    hex: 0x5f00a3,
    rgba: 'rgba(95, 0, 163, 0.75)',
  },
  31: {
    alpha: 0.75,
    hex: 0xa300a3,
    rgba: 'rgba(163, 0, 163, 0.75)',
  },
  32: {
    alpha: 0.75,
    hex: 0x415876,
    rgba: 'rgba(65, 88, 118, 0.75)',
  },
  33: {
    alpha: 0.75,
    hex: 0x0090a3,
    rgba: 'rgba(0, 144, 163, 0.75)',
  },
  34: {
    alpha: 0.75,
    hex: 0x644049,
    rgba: 'rgba(100, 64, 73, 0.75)',
  },
  35: {
    alpha: 0.75,
    hex: 0x659b08,
    rgba: 'rgba(101, 155, 8, 0.75)',
  },
  36: {
    alpha: 0.75,
    hex: 0x9b0854,
    rgba: 'rgba(155, 8, 84, 0.75)',
  },
  37: {
    alpha: 0.75,
    hex: 0x520000,
    rgba: 'rgba(82, 0, 0, 0.75)',
  },
  38: {
    alpha: 0.75,
    hex: 0x523500,
    rgba: 'rgba(82, 53, 0, 0.75)',
  },
  39: {
    alpha: 0.75,
    hex: 0x524500,
    rgba: 'rgba(82, 69, 0, 0.75)',
  },
  40: {
    alpha: 0.75,
    hex: 0x005200,
    rgba: 'rgba(0, 82, 0, 0.75)',
  },
  41: {
    alpha: 0.75,
    hex: 0x000052,
    rgba: 'rgba(0, 0, 82, 0.75)',
  },
  42: {
    alpha: 0.75,
    hex: 0x300052,
    rgba: 'rgba(48, 0, 82, 0.75)',
  },
  43: {
    alpha: 0.75,
    hex: 0x520052,
    rgba: 'rgba(82, 0, 82, 0.75)',
  },
  44: {
    alpha: 0.75,
    hex: 0x243142,
    rgba: 'rgba(36, 49, 66, 0.75)',
  },
  45: {
    alpha: 0.75,
    hex: 0x004852,
    rgba: 'rgba(0, 72, 82, 0.75)',
  },
  46: {
    alpha: 0.75,
    hex: 0x322025,
    rgba: 'rgba(50, 32, 37, 0.75)',
  },
  47: {
    alpha: 0.75,
    hex: 0x324d04,
    rgba: 'rgba(50, 77, 4, 0.75)',
  },
  48: {
    alpha: 0.75,
    hex: 0x4d042a,
    rgba: 'rgba(77, 4, 42, 0.75)',
  },
  49: {
    alpha: 0.75,
    hex: 0xcccccc,
    rgba: 'rgba(204, 204, 204, 0.75)',
  },
};

export const dateTime = {
  MILLISECONDS_PER_SECOND: 1000,
  SECONDS_PER_MINUTE: 60,
};

export const palete = {
  borderColor: { hex: 0x4f4f4f },
};

export const FilterBoxView = {
  MAX_LENGTH: 1024,
};

export const ActiveHighlightColor = {
  BACKGROUND: 0x99cfff,
  BORDER: 0x007eff,
};

export const ActiveHighlightColorHex = {
  BACKGROUND: numberToHexColor(ActiveHighlightColor.BACKGROUND),
  BORDER: numberToHexColor(ActiveHighlightColor.BORDER),
};

export const ZoomConfig = {
  DEFAULT: 100,
  MAX: 200,
  MIN: 20,
  SLIDE_STEP: 2,
  STEP: 10,
};

export const DetailModal = {
  MAX_LENGTH_PER_DETAIL_TITLE: 70,
};

export const PREVIEW_FILE_MAX_SIZE =
  parseInt(process.env.REACT_APP_PREVIEW_MAX_FILE_SIZE_MB || '10') *
  1024 *
  1024;
export const VIEW_FILE_MAX_SIZE = Math.max(
  parseInt(process.env.REACT_APP_VIEW_MAX_FILE_SIZE_MB || '200') * 1024 * 1024,
  PREVIEW_FILE_MAX_SIZE,
);
