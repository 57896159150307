import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { PageUrl } from '../constants';
import { authService } from '../services/AuthService';

export default function Logout() {
  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const sid = query.get('sid');

    if (sid) {
      authService.logout({ sid: sid });
    }
  }, [search]);

  return <Navigate to={PageUrl.LOGIN} />;
}
