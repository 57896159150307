import queryString from 'query-string';

import { ProjectOption } from '../atoms/project';
import {
  ApiDefaultValue,
  ApiUrl,
  PageUrlParam,
  TYPE_PROJECT_CODE,
  TypeView,
  VIEW_OPTION,
} from '../constants';

const convertPageSearchQuery = (
  tags: Array<string> = [],
  view: TypeView = VIEW_OPTION.THUMBNAIL,
  projectCodes: ProjectOption[] = [],
  limit: number = ApiDefaultValue.LIMIT,
  offset: number = ApiDefaultValue.OFFSET,
): string => {
  // queries is for backend, in frontend we're using "q" => legacy
  const searchParamsObject: Record<string, any> = {
    [PageUrlParam.LIMIT]: limit,
    [PageUrlParam.OFFSET]: offset,
    [PageUrlParam.QUERY]: tags,
    [PageUrlParam.VIEW_OPTION]: view,
  };

  if (projectCodes && projectCodes.length > 0) {
    const _projectCodeValues: string[] = [];
    for (let i = 0; i < projectCodes.length; i++) {
      const element = projectCodes[i];
      _projectCodeValues.push(element.value);
    }

    searchParamsObject[PageUrlParam.PROJECT_CODES] = _projectCodeValues;
  }

  const pageSearchQuery = queryString.stringify(searchParamsObject);
  return pageSearchQuery;
};
const parsePageSearchQuery = (query: URLSearchParams): Array<string> => {
  const tagParams: Array<string> = [];
  const arrUnique = Array.from(new Set(query.getAll(PageUrlParam.QUERY)));
  arrUnique.map((item) => {
    if (item) {
      tagParams.push(item);
    }
    return null;
  });
  return tagParams;
};

const getSelectedView = (query: URLSearchParams): TypeView => {
  const mode = query.get(PageUrlParam.VIEW_OPTION);
  if (mode === VIEW_OPTION.THUMBNAIL || mode === VIEW_OPTION.TABLE) {
    return mode;
  }
  return VIEW_OPTION.THUMBNAIL;
};

const getSelectedProjectCodes = (
  query: URLSearchParams,
): TYPE_PROJECT_CODE[] => {
  const projectCodeParameters: TYPE_PROJECT_CODE[] = [];
  const uniqueProjectCodes = Array.from(
    new Set(query.getAll(PageUrlParam.PROJECT_CODES)),
  );
  uniqueProjectCodes
    // .filter((item) => {
    // 	return !inValidProjectCode(item as TYPE_PROJECT_CODE);
    // })
    .map((item) => {
      if (item) {
        projectCodeParameters.push(item as TYPE_PROJECT_CODE);
      }
      return null;
    });
  return projectCodeParameters;
};

const getOffset = (query: URLSearchParams): number => {
  const _offset = query.get(PageUrlParam.OFFSET);
  if (!Number(_offset) || Number(_offset) < 0) {
    return ApiDefaultValue.OFFSET;
  }

  return Number(_offset);
};

const getLimit = (query: URLSearchParams): number => {
  const _limit = query.get(PageUrlParam.LIMIT);
  if (!Number(_limit) || Number(_limit) < 0) {
    return ApiDefaultValue.LIMIT;
  }

  return Number(_limit);
};

const getDocumentNumber = (query: URLSearchParams): string => {
  const _documentNumber = query.get(PageUrlParam.DOCUMENT_NUMBER);
  if (!_documentNumber) {
    return '';
  }

  return _documentNumber;
};

const getRevision = (query: URLSearchParams): string => {
  const _revision = query.get(PageUrlParam.REVISION);
  if (!_revision) {
    return '';
  }

  return _revision;
};

const getUUID = (query: URLSearchParams): string => {
  const _uuid = query.get(PageUrlParam.UUID);
  if (!_uuid) {
    return '';
  }

  return _uuid;
};

const getDownloadFileUrl = (uuid: string): string => {
  return `${ApiUrl.DOWNLOAD_FILE_V2.replace(`{uuid}`, uuid)}`;
};

const getRefreshFileUrl = (uuid: string): string => {
  return `${ApiUrl.REFRESH_FILE_V2.replace(`{uuid}`, uuid)}`;
};

export const urlHelper = {
  convertPageSearchQuery,
  getDocumentNumber,
  getDownloadFileUrl,
  getLimit,
  getOffset,
  getRefreshFileUrl,
  getRevision,
  getSelectedProjectCodes,
  getSelectedView,
  getUUID,
  parsePageSearchQuery,
};
