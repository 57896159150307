import get from 'lodash/get';
import queryString from 'query-string';
import { Link } from 'react-router-dom';

import {
  getQueryParams,
  LEFT_PAGE,
  PAGE_NEIGHBOURS,
  RIGHT_PAGE,
} from '../../helpers';
import { Container, PageItem } from './style';

type IPage = number | string;

const Pagination = (props: any) => {
  const {
    currentPage = 1,
    handleClick,
    pageLimit = 1,
    pageNumbers = [],
  } = props;

  if (!Array.isArray(pageNumbers) || !pageNumbers.length) {
    return null;
  }

  const path = get(window.location, 'pathname', '');

  return (
    <Container>
      {pageNumbers.map((page: IPage, index: number) => {
        const queryStringObj: any = getQueryParams();
        switch (page) {
          case LEFT_PAGE:
            const newLeftPage = Number(currentPage - PAGE_NEIGHBOURS * 2 - 1);
            queryStringObj.offset = newLeftPage * pageLimit;

            const leftUrl = `${path}?${queryString.stringify(queryStringObj)}`;
            return (
              <PageItem key={index}>
                <a
                  href={leftUrl}
                  onClick={(e: any) => handleClick(newLeftPage, e, leftUrl)}
                >
                  <span>
                    <span>{`<<`}</span>
                  </span>
                </a>
              </PageItem>
            );
          case RIGHT_PAGE:
            const newRightPage = Number(currentPage + PAGE_NEIGHBOURS * 2);
            queryStringObj.offset = newRightPage * pageLimit;

            const rightUrl = `${path}?${queryString.stringify(queryStringObj)}`;
            return (
              <PageItem key={index}>
                <a
                  href={rightUrl}
                  onClick={(e: any) => handleClick(newRightPage, e, rightUrl)}
                >
                  <span>
                    <span>{`>>`}</span>
                  </span>
                </a>
              </PageItem>
            );
          default:
            queryStringObj.offset = (Number(page) - 1) * pageLimit;

            const url = `${path}?${queryString.stringify(queryStringObj)}`;
            return (
              <PageItem
                className={currentPage === page ? 'active' : ''}
                key={index}
              >
                <Link
                  onClick={(e: any) => handleClick(Number(page), e, url)}
                  to={url}
                >
                  {new Intl.NumberFormat().format(Number(page))}
                </Link>
              </PageItem>
            );
        }
      })}
    </Container>
  );
};

export default Pagination;
