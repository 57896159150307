import { MouseEventHandler, ReactElement, useState } from 'react';

import {
  AngleLeftIcon,
  AngleRightIcon,
  InvisibleEyeIcon,
  VisibleEyeIcon,
} from '../icons';

interface HighlightedKeywordProps {
  color: string;
  findKeywordIndex: Record<string, number>;
  formattedKeyword: string;
  handleNextHighlight: (keyword: string) => MouseEventHandler;
  handlePreviousHighlight: (keyword: string) => MouseEventHandler;
  index: number;
  keyword: string;
  selectedKeywords: Record<string, boolean>;
  toggleKeyword: (keyword: string) => MouseEventHandler;
  total: number;
}

export function HighlightedKeyword(
  props: HighlightedKeywordProps,
): ReactElement {
  const {
    color,
    formattedKeyword,
    handleNextHighlight,
    handlePreviousHighlight,
    index,
    keyword,
    selectedKeywords,
    toggleKeyword,
    total,
  } = props;

  const [position, setPosition] = useState(0);

  return (
    <div
      className="item mx-2"
      key={index}
      style={{
        backgroundColor: color,
      }}
    >
      {selectedKeywords[formattedKeyword] ? (
        <VisibleEyeIcon
          className={'ic'}
          onClick={toggleKeyword(formattedKeyword)}
        />
      ) : (
        <InvisibleEyeIcon
          className={'ic'}
          onClick={toggleKeyword(formattedKeyword)}
        />
      )}

      {keyword}

      <AngleLeftIcon
        className="ic"
        onClick={(e) => {
          handlePreviousHighlight(formattedKeyword)(e);
          setPosition((prev) => (prev - 1 < 0 ? total : prev - 1));
        }}
      />
      <AngleRightIcon
        className="ic"
        onClick={(e) => {
          handleNextHighlight(formattedKeyword)(e);
          setPosition((prev) => (prev + 1 > total ? 1 : prev + 1));
        }}
      />

      <div className="pr-2">|</div>

      <div>{(position || '-') + ' / ' + total}</div>
    </div>
  );
}
