import queryString from 'query-string';

import { fetchApi } from '@/helpers/api';

import { ApiUrl, PageUrlParam } from '../constants';
import { FileInfoV2 } from './SearchService';

const MAX_DOWNLOAD_FILE_ATTEMPTS = 3;

class ImagePreviewService {
  downloadFile(
    file: FileInfoV2,
    queries: string[],
    abortController = new AbortController(),
    attempts = 1,
  ): [AbortController, Promise<null | Response>] {
    let url = `${ApiUrl.THUMBNAIL_EXTRACTION.replace('{uuid}', file.uuid)}`;

    // queries is for backend, in frontend we're using "q" => legacy
    const paramsObject: Record<string, string[]> = {
      [PageUrlParam.QUERIES]: queries,
    };

    const pageSearchQuery = queryString.stringify(paramsObject);

    url = `${url}?${pageSearchQuery}`;

    const downloadRequest = fetchApi(url, {
      method: 'GET',
      signal: abortController.signal,
    })
      .then(async (response) => {
        if ([0, 200].includes(response.status)) {
          return response;
        } else if (
          response.status === 403 &&
          attempts < MAX_DOWNLOAD_FILE_ATTEMPTS
        ) {
          const [, request] = await this.downloadFile(
            file,
            queries,
            abortController,
            attempts + 1,
          );
          return request;
        }

        return Promise.reject(new Error(response.statusText));
      })
      .catch((error) => {
        console.error(`Aborted ${file.uuid}: ${error}`);
        return null;
      });

    return [abortController, downloadRequest];
  }

  read(
    file: FileInfoV2,
    queries: string[],
    updateProgress?: (progress: number) => void,
  ): [AbortController | undefined, Promise<[Blob | undefined | undefined]>] {
    const [abortController, downloadRequest] = this.downloadFile(file, queries);

    const readFileAsync = new Promise<[Blob | undefined]>(async (resolve) => {
      try {
        abortController.signal.onabort = async () => {
          try {
            await reader?.cancel();
          } catch (error) {
            console.log(`Aborted ${file.uuid}`);
          }
        };

        const response = await downloadRequest;
        const reader = response?.body?.getReader();

        if (!response || !reader) {
          resolve([undefined]);
          return;
        }

        const contentLength = +(response?.headers?.get('Content-Length') || 0);
        const chunks = new Uint8Array(contentLength);
        let receivedLength = 0;

        while (true) {
          const { done, value } = await reader.read();

          if (done || !value) {
            break;
          }

          chunks.set(value, receivedLength);
          receivedLength += value?.length || 0;

          updateProgress &&
            updateProgress(Math.round((receivedLength / contentLength) * 100));
        }

        const blob = new Blob([chunks]);
        resolve([blob]);
      } catch (error) {
        console.error(error);
        resolve([undefined]);
      }
    });

    return [abortController, readFileAsync];
  }
}

export const imagePreviewService = new ImagePreviewService();
