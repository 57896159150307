import { PDFViewer } from 'pdfjs-dist/web/pdf_viewer.js';
import { useEffect, useRef, useState } from 'react';
import { PdfHighlight } from 'react-pdf-highlighter';

import {
  CustomizedPDFFindController,
  CustomizedPdfHighlighter,
  getNextId,
  normalizeRectScale,
  SearchTerm,
} from './pdf';

interface PdfHighlightGroup {
  begin: PdfHighlight;
  end?: PdfHighlight;
  middle?: PdfHighlight[];
}

export const usePdfFind = (
  pdfHighlighter: CustomizedPdfHighlighter | undefined,
  searchTerms: SearchTerm[],
): [PdfHighlight[], Record<string, number[]>] => {
  const [pagesLoaded, setPagesLoaded] = useState(false);
  const [highlights, setHighlights] = useState<PdfHighlight[]>([]);
  const [renderedTextLayers, setRenderedTextLayers] = useState<number[]>([]);
  const [searchedPages, setSearchedPages] = useState<number[]>([]);
  const [matchCountByTermAndPage, setMatchCountByTermAndPage] = useState<
    Record<string, number[]>
  >({});

  const highlightedPageRef = useRef<Record<string, boolean>>({});

  // Clear highlight when searchTerms is changed
  useEffect(() => {
    if (searchTerms?.length === 0) return;

    setHighlights([]);
    setSearchedPages([]);
    highlightedPageRef.current = {};
  }, [searchTerms.length]);

  useEffect(() => {
    if (!pdfHighlighter) return;

    pdfHighlighter.eventBus.on('pagesloaded', () => {
      setPagesLoaded(true);
    });

    pdfHighlighter.eventBus.on(
      'textlayerrendered',
      ({ pageNumber }: { pageNumber: number }) => {
        setRenderedTextLayers((prev) => [...prev, pageNumber - 1]); // Using page index
      },
    );

    pdfHighlighter.eventBus.on(
      'updatetextlayermatches',
      ({
        pageIndex,
        source,
      }: {
        pageIndex: number;
        source: CustomizedPDFFindController;
      }) => {
        if (pageIndex >= 0) {
          setSearchedPages((prev) => [...prev, pageIndex]);

          const foundMatch = searchTerms.some(
            ({ term }) => source.matchCountByTermAndPage[term][pageIndex] > 0,
          );
          if (
            foundMatch ||
            pdfHighlighter.linkService.pagesCount === pageIndex + 1
          ) {
            setMatchCountByTermAndPage({ ...source.matchCountByTermAndPage });
          }
        }
      },
    );
  }, [pdfHighlighter]);

  useEffect(() => {
    // Delay find command a little
    pagesLoaded &&
      setTimeout(() => {
        pdfHighlighter?.pdfFindController.executeCommand('find', {
          entireWord: true,
          highlightAll: true,
          phraseSearch: true,
          queries: searchTerms.map((s) => s.term),
          query: 'dummy', // we will use "queries" instead of "query", but still need a dummy value
          // caseSensitive: true,
          // findPrevious: false,
        });
      }, 0);
  }, [pdfHighlighter, searchTerms, pagesLoaded]);

  useEffect(() => {
    if (!pdfHighlighter) {
      return;
    }

    renderedTextLayers.forEach((pageIndex) => {
      if (
        highlightedPageRef.current[pageIndex] ||
        !searchedPages.includes(pageIndex)
      ) {
        return;
      }

      const textLayer = pdfHighlighter.viewer.getPageView(pageIndex)?.textLayer;
      if (!textLayer) {
        return;
      }

      textLayer._updateMatches();

      const page: HTMLDivElement =
        pdfHighlighter.viewer.viewer.children[pageIndex];
      const pageHighlights = calculatePageHighlights(
        page,
        pageIndex,
        searchTerms,
        pdfHighlighter.viewer,
        pdfHighlighter.pdfFindController.pageMatchesTerm[pageIndex],
      );
      if (pageHighlights.length > 0) {
        setHighlights((prevHighlights) => {
          return [...prevHighlights, ...pageHighlights];
        });
      }

      highlightedPageRef.current[pageIndex] = true;
    });
  }, [renderedTextLayers, searchedPages, searchTerms, pdfHighlighter]);

  return [highlights, matchCountByTermAndPage];
};

const calculatePageHighlights = (
  page: HTMLDivElement,
  pageIndex: number,
  searchTerms: SearchTerm[],
  viewer: typeof PDFViewer,
  pageMatchesTerm: string[],
): PdfHighlight[] => {
  const groups = groupHighlight(
    page,
    pageIndex,
    searchTerms,
    viewer,
    pageMatchesTerm,
  );
  return mergeHighlight(groups);
};

const groupHighlight = (
  page: HTMLDivElement,
  pageIndex: number,
  searchTerms: SearchTerm[],
  viewer: typeof PDFViewer,
  pageMatchesTerm: string[],
): PdfHighlightGroup[] => {
  const highlightSpans = page.querySelectorAll('.highlight');
  if (highlightSpans.length < 1) {
    return [];
  }

  const groups: PdfHighlightGroup[] = [];
  const pageRect = page.getBoundingClientRect();
  const colorByTerm = searchTerms.reduce(
    (prev, cur) => {
      prev[cur.term] = cur.color;
      return prev;
    },
    {} as Record<string, string>,
  );

  let matchIndex = 0;

  highlightSpans.forEach((span) => {
    if (!span.textContent?.trim()) {
      return;
    }

    const isBeginHighlight = span.classList.contains('begin');
    const isMiddleHighlight = span.classList.contains('middle');
    const isEndHighlight = span.classList.contains('end');
    const isSingleHighlight =
      !isBeginHighlight && !isMiddleHighlight && !isEndHighlight;
    const searchTerm = pageMatchesTerm[matchIndex];

    let textContentRect: DOMRect;
    let spanRect: DOMRect;

    if (isMiddleHighlight) {
      const node = document.createTextNode(span.textContent || '');
      const appendSpan = document.createElement('span');
      appendSpan.appendChild(node);

      span.className = '';
      span.textContent = '';
      span.appendChild(appendSpan);
      textContentRect = appendSpan.getBoundingClientRect();

      appendSpan.className = span.className;
      spanRect = appendSpan.getBoundingClientRect();
    } else {
      const className = span.className;
      span.className = '';
      textContentRect = span.getBoundingClientRect();

      span.className = className;
      spanRect = span.getBoundingClientRect();
    }

    const rect = {
      height: pageRect.height, // page height
      width: pageRect.width, // page width
      x1: spanRect.x - pageRect.x, // top-left x
      x2: spanRect.x - pageRect.x + spanRect.width, // bottom-right x
      y1: spanRect.y - pageRect.y, // top-left y
      y2: spanRect.y - pageRect.y + spanRect.height, // bottom-right y
    };

    const textRect = {
      height: pageRect.height, // page height
      width: pageRect.width, // page width
      x1: textContentRect.x - pageRect.x, // top-left x
      x2: textContentRect.x - pageRect.x + textContentRect.width, // bottom-right x
      y1: textContentRect.y - pageRect.y, // top-left y
      y2: textContentRect.y - pageRect.y + textContentRect.height, // bottom-right y
    };

    const id = getNextId();
    const highlight: PdfHighlight = {
      background: colorByTerm[searchTerm],
      content: {
        text: searchTerm,
      },
      id,
      position: {
        boundingRect: {
          ...rect,
        },
        // temp index, will be calculated when merge with ocr highlight
        indexInFile: -1,
        indexInPage: -1,
        pageNumber: pageIndex + 1,
        rects: [
          {
            ...rect,
          },
        ],
        scaledBoundingRect: normalizeRectScale(pageIndex + 1, rect, viewer),
        textBoundingRect: textRect,
      },
      type: 'text',
    };

    if (isSingleHighlight || isBeginHighlight) {
      groups.push({
        begin: highlight,
      });
      matchIndex++;
      return;
    }

    const lastHighlightGroup = groups[groups.length - 1];
    if (isMiddleHighlight) {
      if (!lastHighlightGroup.middle) {
        lastHighlightGroup.middle = [];
      }
      lastHighlightGroup.middle.push(highlight);
      return;
    }

    lastHighlightGroup.end = highlight;
  });

  return groups;
};

const mergeHighlight = (groups: PdfHighlightGroup[]): PdfHighlight[] => {
  const highlights: PdfHighlight[] = [];

  groups.forEach((group) => {
    const highlight = group.begin;
    highlights.push(highlight);

    const highlightParts = [...(group.middle || [])];
    if (group.end) {
      highlightParts.push(group.end);
    }

    highlightParts.forEach((part) => {
      if (isNewlineHighlightPart(highlight, part)) {
        highlight.position.rects.push(part.position.boundingRect);
      } else {
        const lastRect =
          highlight.position.rects[highlight.position.rects.length - 1];
        lastRect.x2 = part.position.boundingRect.x2;
      }
    });
  });

  return highlights;
};

const isNewlineHighlightPart = (
  highlight: PdfHighlight,
  part: PdfHighlight,
) => {
  const lineHeight =
    highlight.position.boundingRect.y2 - highlight.position.boundingRect.y1;
  return (
    highlight.position.boundingRect.y1 + lineHeight / 2 <=
    part.position.boundingRect.y1
  );
};
