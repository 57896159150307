import { useMsal } from '@azure/msal-react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { authService } from '../../services/AuthService';

export default function LogoutButton() {
  const { t } = useTranslation();
  const { instance } = useMsal();

  const handleLogout = async () => {
    try {
      await authService.preLogout();
    } catch (error) {
      console.warn(`Failed to prepare logout: ${(error as Error).message}`);
    }

    instance.logoutRedirect();
  };

  return (
    <Button onClick={handleLogout} type="button" variant="danger">
      {t('landing_button_sign_out')}
    </Button>
  );
}
