import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import Clarity from '@microsoft/clarity';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useTrackEvents() {
  const appInsights = useAppInsightsContext();
  const [searchParams] = useSearchParams();

  const trackClickToSearch = useCallback(
    (keywords) => {
      appInsights?.trackEvent({
        name: 'onClickToSearch',
        properties: {
          keywords,
        },
      });
      Clarity?.event(`Click on Search button with ${keywords}`);
    },
    [appInsights],
  );

  const trackPressEnterToSearch = useCallback(
    (keywords) => {
      appInsights?.trackEvent({
        name: 'onPressEnterToSearch',
        properties: {
          keywords,
        },
      });
      Clarity?.event(`Press Enter to search with ${keywords}`);
    },
    [appInsights],
  );

  const trackDocumentPreview = useCallback(
    (documentNo) => {
      appInsights?.trackEvent({
        name: 'onPreviewDocument',
        properties: {
          documentNo,
        },
      });

      Clarity?.event(`Preview document`);

      const view = searchParams.get('view');

      Clarity?.event(
        view === 'thumbnail'
          ? `Preview document in thumbnail view`
          : 'Preview document in table view',
      );

      Clarity?.event(`Click on document thumbnail to preview document`);
      Clarity?.event(
        `Click on document thumbnail to preview document ${documentNo}`,
      );
    },
    [appInsights, searchParams],
  );

  const trackPreviewDocumentButtonClick = useCallback(
    (documentNo) => {
      appInsights?.trackEvent({
        name: 'onPreviewDocumentButtonClick',
        properties: {
          documentNo,
        },
      });

      Clarity?.event(`Preview document`);

      const view = searchParams.get('view');

      Clarity?.event(
        view === 'thumbnail'
          ? `Preview document in thumbnail view`
          : 'Preview document in table view',
      );

      Clarity?.event(`Click on Preview button to preview document`);
      Clarity?.event(
        `Click on Preview button to preview document ${documentNo}`,
      );
    },
    [appInsights, searchParams],
  );

  const trackDownloadButtonClick = useCallback(
    (documentNo) => {
      appInsights?.trackEvent({
        name: 'onDownloadButtonClick',
        properties: {
          documentNo,
        },
      });
      Clarity?.event(
        `Click on Download button to download document ${documentNo}`,
      );
    },
    [appInsights],
  );

  const trackDocumentLinkCopy = useCallback(
    (documentNo) => {
      appInsights?.trackEvent({
        name: 'onDocumentLinkCopy',
        properties: {
          documentNo,
        },
      });
      Clarity?.event(`Click on Copy Link button on document ${documentNo}`);
    },
    [appInsights],
  );

  const trackProjectsSelect = useCallback(
    (projects: string[]) => {
      appInsights?.trackEvent({
        name: 'onProjectSelect',
        properties: {
          projects,
        },
      });

      Clarity?.event(`Change project filter`);
      projects?.forEach((project) =>
        Clarity?.event(`Select project ${project}`),
      );
    },
    [appInsights],
  );

  const trackViewChange = useCallback((view) => {
    Clarity?.event(`Change current view to ${view}`);
  }, []);

  return {
    trackClickToSearch,
    trackDocumentLinkCopy,
    trackDocumentPreview,
    trackDownloadButtonClick,
    trackPressEnterToSearch,
    trackPreviewDocumentButtonClick,
    trackProjectsSelect,
    trackViewChange,
  };
}
