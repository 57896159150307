import { useIsAuthenticated } from '@azure/msal-react';
// import { useSetAtom } from 'jotai';
import { Container, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { Logo } from '@/components/commons/Logo';
import { UserInfo } from '@/components/commons/UserInfo';

// import {
//   preselectedProjectsAtom,
//   preselectedViewAtom,
// } from '@/components/search/SearchInput';
import { PageUrl } from '../../../constants';
import Help from './Help';

export const Header = () => {
  const isAuthenticated = useIsAuthenticated();
  const { t } = useTranslation();
  const headerText = t('header_text_logo').split(' ');
  const { pathname } = useLocation();
  // const setPreselectedProjects = useSetAtom(preselectedProjectsAtom);
  // const setPreselectedView = useSetAtom(preselectedViewAtom);

  return (
    <Navbar
      className={twMerge(
        'header !bg-[#434343]',
        isAuthenticated && pathname === PageUrl.HOME && '!bg-transparent',
      )}
    >
      <Container fluid>
        {isAuthenticated && pathname !== PageUrl.HOME && (
          <Link
            to={PageUrl.HOME}
            // onClick={() => {
            //   setPreselectedProjects([]);
            //   setPreselectedView('');
            // }}
          >
            <Logo />
            <span>
              <strong>{headerText[0]}</strong> {headerText[1]}
            </span>
          </Link>
        )}

        {isAuthenticated && (
          <div className="ml-auto flex items-center gap-4">
            <Help />
            <UserInfo />
          </div>
        )}
      </Container>
    </Navbar>
  );
};
