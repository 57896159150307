import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
} from '@azure/msal-react';
import { useAtomValue } from 'jotai';
import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  preselectedProjectsAtom,
  preselectedViewAtom,
} from '@/components/search/SearchInput';

import { ReactComponent as Logo } from '../assets/img/logo-bing.svg';
import { LoginButton } from '../components/buttons/LoginButton';
import { SearchBox } from '../components/search/SearchBox';
import { PageUrl } from '../constants';
import { clarityService } from '../services';

export default function Home(): ReactElement {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated === true && clarityService) {
      clarityService.trackingIdentifyWithLocation();
    }
  }, [isAuthenticated]);

  const preselectedProjects = useAtomValue(preselectedProjectsAtom);
  const preselectedView = useAtomValue(preselectedViewAtom);

  const onSearch = (tags: string[]) => {
    const searchParams = new URLSearchParams({
      limit: '6',
      offset: '0',
      view: preselectedView || 'thumbnail',
    });

    tags.forEach((tag) => {
      searchParams.append('q', tag);
    });

    preselectedProjects.forEach((projectCode) => {
      searchParams.append('project_codes', projectCode);
    });

    navigate({
      pathname: PageUrl.RESULTS,
      search: searchParams.toString(),
    });
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center h-100 w-100">
      <Logo className="mb-10" />

      <UnauthenticatedTemplate>
        <LoginButton />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <div className="d-flex flex-column align-items-center w-75">
          <SearchBox
            isSearching={false}
            onSearch={onSearch}
            searchButtonPosition="bottom"
          />
        </div>
      </AuthenticatedTemplate>
    </div>
  );
}
