import { atom } from 'jotai';
import { atomWithQuery } from 'jotai-tanstack-query';

import { fetchJsonApi } from '@/helpers/api';
import { msalInstance } from '@/helpers/msal';
import { clarityService } from '@/services';
import { SearchResponseV2 } from '@/services/SearchService';

import { searchLogQueryAtom } from './searchLog';

export const documentListQueriesAtom = atom<string[]>([]);
export const documentListProjectCodesAtom = atom<string[]>([]);
export const documentListLimitAtom = atom(6);
export const documentListOffsetAtom = atom(0);
export const documentListViewTypeAtom = atom('');

export const documentListAtom = atomWithQuery((get) => ({
  enabled: get(documentListQueriesAtom).length > 0,
  placeholderData: {
    data: [],
    total_count: 0,
  },
  queryFn: async (): Promise<SearchResponseV2> => {
    try {
      const data = {
        limit: String(get(documentListLimitAtom)),
        offset: String(get(documentListOffsetAtom)),
        view_type: get(documentListViewTypeAtom),
      };
      const searchParams = new URLSearchParams(data);

      get(documentListQueriesAtom).forEach((query: string) => {
        searchParams.append('queries', query);
      });

      get(documentListProjectCodesAtom).forEach((projectCode: string) => {
        searchParams.append('project_codes', projectCode);
      });

      const activeAccount = msalInstance.getActiveAccount();
      searchParams.append('user_email', activeAccount?.username ?? '');

      const response = await fetchJsonApi(
        '/search/all?' + searchParams.toString(),
      );

      clarityService.trackingSearchHistory();

      return response;
    } catch (error) {
      throw error;
      // } finally {
      //   get(searchLogQueryAtom).refetch();
    }
  },
  queryKey: [
    'documentList',
    get(documentListQueriesAtom),
    get(documentListProjectCodesAtom),
    get(documentListLimitAtom),
    get(documentListOffsetAtom),
    get(documentListOffsetAtom),
  ],
}));
