import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { TYPE_PROJECT_CODE, TypeView } from '../constants';
import { urlHelper } from '../helpers/url';

interface SearchQuery {
  document_number?: string;
  limit?: number;
  offset?: number;
  project_codes: TYPE_PROJECT_CODE[];
  revision?: string;
  tags: string[];
  uuid?: string;
  view: TypeView;
}

const getSearchQuery = (location): SearchQuery => {
  const query = new URLSearchParams(location.search);
  return {
    document_number: urlHelper.getDocumentNumber(query),
    limit: urlHelper.getLimit(query),
    offset: urlHelper.getOffset(query),
    project_codes: urlHelper.getSelectedProjectCodes(query),
    revision: urlHelper.getRevision(query),
    tags: urlHelper.parsePageSearchQuery(query),
    uuid: urlHelper.getUUID(query),
    view: urlHelper.getSelectedView(query),
  };
};

export const useSearchQuery = (): SearchQuery => {
  const location = useLocation();
  const [query, setQuery] = useState<SearchQuery>(getSearchQuery(location));

  useEffect(() => {
    setQuery(getSearchQuery(location));
  }, [location]);

  return query;
};
