import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';

const reactPlugin = new ReactPlugin();
const browserHistory = createBrowserHistory();

const clickPluginInstance = new ClickAnalyticsPlugin();
const clickPluginConfig = {
  autoCapture: true,
};

export const appInsights = new ApplicationInsights({
  config: {
    connectionString:
      process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING,
    enableAutoRouteTracking: true,
    extensionConfig: {
      [clickPluginInstance.identifier]: clickPluginConfig,
      [reactPlugin.identifier]: { history: browserHistory },
    },
    extensions: [reactPlugin, clickPluginInstance],
  },
});
