import { useAccount, useMsal } from '@azure/msal-react';
import { ReactElement, useRef, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';

import LogoutButton from '../buttons/LogoutButton';
import { UserIcon } from '../icons';

export function UserInfo(): ReactElement {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleShowPopover = (event: any) => {
    setShow(!show);
    setTarget(event.target);
  };

  return (
    <div ref={ref}>
      <div className="btn-avatar" onClick={handleShowPopover} role="button">
        <UserIcon className="ic-large" />
        <Overlay
          container={ref.current}
          containerPadding={16}
          onHide={() => setShow(false)}
          placement="bottom"
          rootClose={true}
          show={show}
          target={target}
          transition={false}
        >
          <Popover id="popover-user-info">
            <Popover.Content className="d-flex flex-column justify-content-center align-items-center px-0 py-3">
              <div>
                <UserIcon />
              </div>
              <span className="font-weight-bold mb-1 mt-2">
                {account ? account.name : ''}
              </span>
              <span className="small mt-2">
                {account ? account.username : ''}
              </span>
              <hr className="w-100 mb-3 mt-3" />
              <LogoutButton />
            </Popover.Content>
          </Popover>
        </Overlay>
      </div>
    </div>
  );
}
