import styled from 'styled-components/macro';

export const Container = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding-left: 0;
  height: 48px;
`;

export const PageItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  min-width: 32px;
  height: 32px;

  transition: 0.3s;

  margin-right: 1rem;
  &:last-child {
    margin-right: 0;
  }

  background: #262626;
  color: #fff;

  &:hover,
  &:active,
  &:focus {
    cursor: pointer;
    border-color: transparent;
    a {
      color: #fff;
    }
  }

  > a {
    padding: 8px;
    width: 100%;
    text-align: center;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    color: #fff;
  }

  &.active {
    border-color: transparent;
    > a {
      height: 100%;
      color: #9fadff;
      cursor: pointer;
    }
  }
`;
