import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import { useTrackEvents } from '@/analytics/use-track-events';
import { tagsAtom } from '@/atoms/searchLog';

import SearchInput, {
  preselectedProjectsAtom,
  preselectedViewAtom,
  searchInputValueAtom,
} from './SearchInput';

interface SearchBoxProps {
  fluid?: boolean;
  isSearching: boolean;
  onSearch: (tags: string[]) => void;
  searchButtonPosition: 'bottom' | 'right';
  tags?: Array<string>;
}

export function SearchBox(props: SearchBoxProps) {
  const { t } = useTranslation();
  const { fluid, isSearching, onSearch, searchButtonPosition } = props;
  const selectedTags = useAtomValue(tagsAtom);
  const setPreselectedProjects = useSetAtom(preselectedProjectsAtom);
  const setPreselectedView = useSetAtom(preselectedViewAtom);
  const [searchInputValue, setSearchInputValue] = useAtom(searchInputValueAtom);
  const disabled =
    (searchInputValue.length < 2 && selectedTags.length === 0) || isSearching;
  const { trackClickToSearch, trackPressEnterToSearch } = useTrackEvents();

  const onTagsSearch = useCallback(
    (availableTags) => {
      onSearch(availableTags);
      setSearchInputValue('');
      setPreselectedProjects([]);
      setPreselectedView('');
    },
    [onSearch, setPreselectedProjects, setPreselectedView, setSearchInputValue],
  );

  return (
    <>
      <Container
        className={twMerge(
          `search-box d-flex align-items-center pl-0 pr-0`,
          fluid && 'search-box-full',
        )}
        fluid
        style={{ gap: '0.8rem' }}
      >
        <SearchInput
          onSearch={() => {
            const availableTags = [...selectedTags];

            if (
              searchInputValue.length > 1 &&
              !availableTags.includes(searchInputValue)
            ) {
              availableTags.push(searchInputValue);
            }

            onTagsSearch(availableTags);
            trackPressEnterToSearch(availableTags.sort().join(' '));
          }}
        />

        {searchButtonPosition === 'right' && (
          <Button
            disabled={disabled}
            onClick={() => {
              const availableTags = [...selectedTags];

              if (
                searchInputValue.length > 1 &&
                !availableTags.includes(searchInputValue)
              ) {
                availableTags.push(searchInputValue);
              }

              onTagsSearch(availableTags);
              trackClickToSearch(availableTags.sort().join(' '));
            }}
            size="sm"
            type="button"
          >
            {t('search_box_button_search')}
          </Button>
        )}
      </Container>

      {searchButtonPosition === 'bottom' && (
        <Button
          className="mt-5"
          disabled={disabled}
          onClick={() => {
            const availableTags = [...selectedTags];

            if (
              searchInputValue.length > 1 &&
              !availableTags.includes(searchInputValue)
            ) {
              availableTags.push(searchInputValue);
            }

            onTagsSearch(availableTags);
            trackClickToSearch(availableTags.sort().join(' '));
          }}
          size="sm"
        >
          {t('search_box_button_search')}
        </Button>
      )}
    </>
  );
}
