import axios, { AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';

import { ApiConfig, ApiStatusCode, PageUrl } from '../constants';
import { msal } from '../helpers/msal';

export interface HttpClientParameters<T = any> {
  cancelToken?: CancelToken;
  payload?: T;
  requiresToken?: boolean;
  responseBlob?: boolean;
  url: string;
}

interface RequestConfig extends AxiosRequestConfig {
  cancelToken?: CancelToken;
  requiresToken?: boolean;
}

const instance = axios.create({
  baseURL: ApiConfig.API_URL,
  timeout: ApiConfig.API_TIMEOUT,
  withCredentials: false,
});

// Request access token interceptor for API calls
instance.interceptors.request.use(
  async (config: RequestConfig) => {
    const { requiresToken } = config;

    if (requiresToken) {
      const token = await msal.getAccessTokenAsync();

      config.headers = {
        ...(config.headers || {}),
        Authorization: `Bearer ${token}`,
      };
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Un-authenticate interceptor for API calls
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (
      error.response &&
      error.response.status === ApiStatusCode.UNAUTHENTICATED
    ) {
      msal.removeAccount();
      window.location.href = PageUrl.LOGIN;
    } else {
      return Promise.reject(error);
    }
  },
);

class HttpClient {
  createRequestConfig(parameters: HttpClientParameters): RequestConfig {
    const { cancelToken, requiresToken, responseBlob } = parameters;
    const options: RequestConfig = {
      cancelToken,
      requiresToken,
    };

    if (responseBlob) {
      options.responseType = 'arraybuffer';
    }

    return options;
  }

  async delete(
    parameters: HttpClientParameters,
  ): Promise<AxiosResponse['data']> {
    const { payload, url } = parameters;

    const response = await instance.delete(url, {
      data: payload,
      ...this.createRequestConfig(parameters),
    });
    return response.data;
  }

  async get(parameters: HttpClientParameters): Promise<AxiosResponse['data']> {
    const { url } = parameters;
    const response = await instance.get(
      url,
      this.createRequestConfig(parameters),
    );
    return response.data;
  }

  async getWithProgress(
    parameters: HttpClientParameters,
    callback?: (progress: number) => void,
  ): Promise<AxiosResponse['data']> {
    const { url } = parameters;
    const options = this.createRequestConfig(parameters);
    if (typeof callback === 'function') {
      options.onDownloadProgress = (progressEvent: any) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        callback(percentCompleted);
      };
    }

    const response = await instance.get(url, options);
    return response.data;
  }

  async post(parameters: HttpClientParameters): Promise<AxiosResponse['data']> {
    const { payload, url } = parameters;
    const response = await instance.post(
      url,
      payload,
      this.createRequestConfig(parameters),
    );
    return response.data;
  }

  async postV2(
    parameters: HttpClientParameters,
  ): Promise<AxiosResponse['data']> {
    const { payload, url } = parameters;
    const response = await instance.post(
      url,
      payload,
      this.createRequestConfig(parameters),
    );
    return response;
  }

  async put(parameters: HttpClientParameters): Promise<AxiosResponse['data']> {
    const { payload, url } = parameters;
    const response = await instance.put(
      url,
      payload,
      this.createRequestConfig(parameters),
    );
    return response.data;
  }
}

export const httpClient = new HttpClient();
