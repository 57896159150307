import { ApiConfig } from '@/constants';

import { msal } from './msal';

export const fetchJsonApi = async (url: string, options?: RequestInit) => {
  const token = await msal.getAccessTokenAsync();

  return fetch(ApiConfig.API_URL + url, {
    ...options,
    headers: {
      Authorization: `Bearer ${token}`,
      ...options?.headers,
    },
  }).then((response) => response.json());
};

export const fetchApi = async (url: string, options?: RequestInit) => {
  const token = await msal.getAccessTokenAsync();

  return fetch(ApiConfig.API_URL + url, {
    ...options,
    headers: {
      Authorization: `Bearer ${token}`,
      ...options?.headers,
    },
  });
};
