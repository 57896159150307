import { useSetAtom } from 'jotai';
import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import {
  Highlight,
  HighlightRect,
  PdfHighlight,
  PdfLoader,
  PdfScale,
} from 'react-pdf-highlighter';
import { scaledToViewport } from 'react-pdf-highlighter/build/lib/coordinates.js';
import { useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { viewerAtom } from '@/atoms/viewer';

import { ActiveHighlightColorHex } from '../../constants/common';
import { getPreviewFromQuerystring, getQueryParams } from '../../helpers';
import { trimSearchTermForPdf } from '../../helpers/utils';
import { Spinner } from '../commons';
import {
  CustomizedPdfHighlighter,
  findNearHighlight,
  indexHighlightByTermAndPage,
  MoveDirection,
  SearchTerm,
  sortHighlight,
} from './pdf';
import { PdfViewerProps } from './PdfViewer.interface';
import { useOcrHighlight } from './useOcrHighlight';
import { usePdfFind } from './usePdfFind';

// const MINIMUM_LINE_HEIGHT = 18;
const ACTIVE_HIGHLIGHT_PADDING = 2;

const convertHighlightRects = (highlight: PdfHighlight, isActive: boolean) => {
  return highlight.position.rects.map((rect, index, rects) => {
    const highlightRect = {
      ...rect,
      // background: 'transparent',
      background: highlight.background,
    } as any;

    if (isActive) {
      highlightRect.borderColor = ActiveHighlightColorHex.BORDER;
      highlightRect.left =
        (rect as unknown as HighlightRect).left - ACTIVE_HIGHLIGHT_PADDING;
      highlightRect.top = (rect as unknown as HighlightRect).top - 3;
      highlightRect.width = rect.width + ACTIVE_HIGHLIGHT_PADDING * 6;
      highlightRect.height = rect.height + ACTIVE_HIGHLIGHT_PADDING * 2;

      if (rects.length > 1) {
        if (index < rects.length - 1) {
          highlightRect.borderRight = 'none';
        }

        if (index > 0) {
          highlightRect.borderLeft = 'none';
        }
      }
    }

    if (highlight.rotation) {
      highlightRect.transform = `rotate(${highlight.rotation}deg)`;
    }

    return highlightRect;
  });
};

const createHighlight = (
  highlight: PdfHighlight,
  isActive: boolean,
  onClickHighlight: (highlightId: string) => void,
) => {
  return (
    <Highlight
      isScrolledTo={isActive}
      key={highlight.id}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClickHighlight(highlight.id);
      }}
      position={{
        boundingRect: highlight.position
          .boundingRect as unknown as HighlightRect,
        rects: convertHighlightRects(highlight, isActive),
      }}
    />
  );
};

export const PdfViewer = ({
  detailPageUrl,
  focusFirstHighlight = false,
  highlightToScroll: highlightToScrollProp,
  onClick,
  onFocusHighlightChange,
  onScaleChange,
  pdfScale = 'page-actual',
  pdfViewMode = 'preview',
  positions,
  scrollable = true,
  searchTerms,
  setPdfViewerUtility,
  url,
}: PdfViewerProps) => {
  const pdfLoader = useRef<PdfLoader>(null);
  const [pdfHighlighter, setPdfHighlighter] =
    useState<CustomizedPdfHighlighter>();
  const [sortedSearchTerms, setSortedSearchTerms] = useState<SearchTerm[]>([]);
  const [, textMatchCountByTermAndPage] = usePdfFind(
    pdfHighlighter,
    sortedSearchTerms,
  );
  const ocrHighlights = useOcrHighlight(
    pdfHighlighter,
    sortedSearchTerms,
    positions,
  );
  const [highlights, setHighlights] = useState<PdfHighlight[]>([]);
  const [activeHighlights, setActiveHighlights] = useState<PdfHighlight[]>([]);
  const [activeHighlightState, setActiveHighlightState] = useState<
    Record<string, boolean>
  >({});
  const [scale, setScale] = useState<PdfScale>(pdfScale);
  const [isIdle, setIsIdle] = useState(false);
  const [focusingHighlight, setFocusingHighlight] = useState<PdfHighlight>();
  const [queryStringParameters] = useState(getQueryParams());
  const [previewFeature] = useState<boolean>(() => {
    return getPreviewFromQuerystring();
  });

  const [findingFirstHighlight, setFindingFirstHighlight] =
    useState<boolean>(focusFirstHighlight);
  const [pageToScroll, setPageToScroll] = useState<number>(-1);
  const [searchTermToScroll, setSearchTermToScroll] = useState<string>('');
  const [highlightIndexInPageToScroll, setHighlightIndexInPageToScroll] =
    useState<'first' | 'last' | number>(-1);
  const [highlightToScroll, setHighlightToScroll] = useState<PdfHighlight>();

  const [matchSearchTerms, setMatchSearchTerms] = useState<
    Record<string, number>
  >({});
  const [matchCountByTermAndPage, setMatchCountByTermAndPage] = useState<
    Record<string, Record<number, number>>
  >({});

  const pdfViewerRef = useRef<HTMLDivElement>(null);
  const notFoundSearchTerm = useRef<Set<string>>(new Set());

  const highlightsRef = useRef<PdfHighlight[]>(highlights);
  highlightsRef.current = highlights;

  const highlightToScrollRef = useRef<PdfHighlight>();
  highlightToScrollRef.current = highlightToScroll;

  const focusingHighlightRef = useRef<PdfHighlight>();
  focusingHighlightRef.current = focusingHighlight;

  const voidFunc = useCallback(() => {
    return;
  }, []);

  const setViewer = useSetAtom(viewerAtom);

  useEffect(() => {
    if (pdfHighlighter) {
      setViewer(pdfHighlighter.viewer);
    }
  }, [pdfHighlighter, setViewer]);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (pdfHighlighter) {
      // Tracking renderer idling
      pdfHighlighter.eventBus.on('pagerender', () => {
        setIsIdle(false);
      });

      pdfHighlighter.eventBus.on('textlayerrendered', (event) => {
        if (pdfHighlighter.viewer.renderingQueue.idleTimeout) {
          setIsIdle(true);
        }
      });

      pdfHighlighter.eventBus.on('pagesloaded', () => {
        if (searchParams.get('page')) {
          pdfHighlighter.viewer.scrollPageIntoView({
            pageNumber: Number(searchParams.get('page')),
          });
        }
      });

      onScaleChange &&
        pdfHighlighter.eventBus.on('scalechanging', ({ scale }: any) => {
          onScaleChange(scale);
        });
    }
  }, [pdfHighlighter]);

  // Scroll to selected highlight
  useEffect(() => {
    if (!pdfHighlighter || !highlightToScrollProp) {
      return;
    }

    if (focusingHighlightRef.current?.id === highlightToScrollProp.id) {
      // Re-focus highlight
      setHighlightToScroll(highlightToScrollProp);
    } else {
      const {
        content: { text },
        position: { indexInPage: index, pageNumber },
      } = highlightToScrollProp;
      setPageToScroll(pageNumber - 1);
      setSearchTermToScroll(text);
      setHighlightIndexInPageToScroll(index);
    }
  }, [pdfHighlighter, highlightToScrollProp]);

  // sort search term by text length
  useEffect(() => {
    setSortedSearchTerms(
      [...searchTerms]
        .sort((t1, t2) => t2.term.length - t1.term.length)
        .map((t) => {
          return {
            ...t,
            term: trimSearchTermForPdf(t.term),
          };
        }),
    );
  }, [searchTerms]);

  // Quan says: I don't know why but we used to double the count to make sure we cover all the results from backend (said Phan)
  // Via this change, I'm gonna count only what `positions` object has for each keyword accordingly
  // Count match
  useEffect(() => {
    const _matchSearchTerms: Record<string, number> = {};
    const _matchCountByTermAndPage: Record<string, Record<number, number>> = {};
    const addMatchCount = (term: string, pageIndex: number, count: number) => {
      _matchSearchTerms[term] = (_matchSearchTerms[term] || 0) + count;
      if (!_matchCountByTermAndPage[term]) {
        _matchCountByTermAndPage[term] = {};
      }
      _matchCountByTermAndPage[term][pageIndex] =
        (_matchCountByTermAndPage[term][pageIndex] || 0) + count;
    };

    // Count ocr match
    ocrHighlights.forEach(({ content: { text }, position: { pageNumber } }) =>
      addMatchCount(text, pageNumber - 1, 1),
    );

    setMatchSearchTerms(_matchSearchTerms);
    setMatchCountByTermAndPage(_matchCountByTermAndPage);
  }, [ocrHighlights]);

  // said Quan: We now take only ocr highlight
  useEffect(() => {
    const mergedHighlights = [...ocrHighlights];
    indexHighlightByTermAndPage(mergedHighlights, matchCountByTermAndPage);
    setHighlights(mergedHighlights);
  }, [ocrHighlights, matchCountByTermAndPage]);

  // Set active highlights
  useEffect(() => {
    setActiveHighlights(
      highlights.filter(
        (h) =>
          activeHighlightState[h.content.text] === undefined ||
          activeHighlightState[h.content.text],
      ),
    );
  }, [highlights, activeHighlightState]);

  // Update pdf utility
  useEffect(() => {
    if (!setPdfViewerUtility) {
      return;
    }

    const moveToNearHighlight = (
      searchTerm: string,
      direction: MoveDirection,
    ) => {
      const trimmedSearchTerm = trimSearchTermForPdf(searchTerm);
      const [pageNo, highlightIndexInPage, highlightIndex] = findNearHighlight(
        trimmedSearchTerm,
        direction,
        highlightsRef.current,
        matchCountByTermAndPage,
        focusingHighlightRef.current,
      );
      setPageToScroll(pageNo);
      setSearchTermToScroll(trimmedSearchTerm);
      setHighlightIndexInPageToScroll(highlightIndexInPage);

      return highlightIndex;
    };

    const toggleHighlight = (searchTerm: string, isActive: boolean) => {
      const trimmedSearchTerm = trimSearchTermForPdf(searchTerm);

      setActiveHighlightState((prevState) => {
        return {
          ...prevState,
          [trimmedSearchTerm]: isActive,
        };
      });
    };

    setPdfViewerUtility({
      getMatchSearchTerms: () => matchSearchTerms,
      nextHighlight: (searchTerm) => moveToNearHighlight(searchTerm, 'next'),
      prevHighlight: (searchTerm) => moveToNearHighlight(searchTerm, 'prev'),
      toggleHighlight,
      viewer: {
        fitHeight: () => {
          setScale('page-height');
        },
        fitWidth: () => {
          setScale('page-width');
        },
        resetZoom: () => {
          setScale('page-actual');
          const pageNumber = pdfHighlighter?.viewer.currentPageNumber;
          const pageViewport = pdfHighlighter?.viewer.getPageView(
            pageNumber - 1,
          ).viewport;
          pdfHighlighter?.viewer.scrollPageIntoView({
            destArray: [
              null,
              {
                name: 'XYZ',
              },
              ...pageViewport.convertToPdfPoint(0, 0),
            ],
            pageNumber: pdfHighlighter.viewer.currentPageNumber,
          });
        },
        zoom: setScale,
      },
    });
  }, [matchSearchTerms, matchCountByTermAndPage, setPdfViewerUtility]);

  // Finding first highlight to scroll to
  useEffect(() => {
    if (!findingFirstHighlight) {
      return;
    }

    const numPages = pdfHighlighter?.viewer?.pdfDocument?.numPages || 0;
    if (numPages <= 0) {
      return;
    }

    let foundSearchTerm: string | undefined;
    let firstPageHasTextHighlight: number | undefined;
    let firstOcrHighlight: PdfHighlight | undefined;

    while (true) {
      const searchTerm = searchTerms
        .map(({ term }) => trimSearchTermForPdf(term))
        .find((term) => !notFoundSearchTerm.current.has(term));

      if (!searchTerm) {
        // All search terms not found any match, skip scroll to highlight
        setFindingFirstHighlight(false);
        onFocusHighlightChange && onFocusHighlightChange();
        return;
      }

      const textMatchCountByPage = textMatchCountByTermAndPage[searchTerm];
      if (!textMatchCountByPage) {
        // Didn't search text, so skip
        return;
      }

      firstPageHasTextHighlight = textMatchCountByPage
        .map((count, i) => i)
        .find((i) => textMatchCountByPage[i] > 0);
      firstOcrHighlight = ocrHighlights.find(
        (h) => h.content.text === searchTerm,
      );

      // Compare firstPageHasTextHighlight and "undefined" because 0 is falsy also
      if (firstPageHasTextHighlight === undefined) {
        if (textMatchCountByPage.length !== pdfHighlighter?.viewer.pagesCount) {
          // Didn't finish search text, so skip
          return;
        }

        if (!firstOcrHighlight) {
          // Not found any highlight, move to next term
          notFoundSearchTerm.current.add(searchTerm);
          continue;
        } else {
          // If ocr highlight was found, but we didn't search text in  page of the ocr highlight,
          // We need to wait until text is searched
          if (
            textMatchCountByPage[firstOcrHighlight.position.pageNumber - 1] ===
            undefined
          ) {
            return;
          }
        }
      }

      // Found highlight, break the loop
      foundSearchTerm = searchTerm;
      break;
    }

    // Find first page has highlight
    const pageIndices: number[] = [];
    if (firstPageHasTextHighlight !== undefined) {
      pageIndices.push(firstPageHasTextHighlight);
    }
    if (firstOcrHighlight) {
      pageIndices.push(firstOcrHighlight.position.pageNumber - 1);
    }
    const pageIndex = pageIndices.length > 0 ? Math.min(...pageIndices) : -1;

    if (pageIndex >= 0) {
      setPageToScroll(pageIndex);
      setSearchTermToScroll(foundSearchTerm);
      setHighlightIndexInPageToScroll('first');
      setFindingFirstHighlight(false);

      if (pdfViewMode === 'preview') {
        pdfHighlighter?.pdfFindController.stopFind();
      }
    }
  }, [
    findingFirstHighlight,
    searchTerms,
    pdfHighlighter,
    textMatchCountByTermAndPage,
    ocrHighlights,
  ]);

  // Scroll to target page, skip if it is current page
  useEffect(() => {
    if (!isIdle || !pdfHighlighter || pageToScroll < 0) {
      return;
    }

    const pageNumber = pageToScroll + 1;
    if (pdfHighlighter.viewer.currentPageNumber !== pageNumber) {
      pdfHighlighter.viewer.scrollPageIntoView({
        pageNumber,
      });
      setPageToScroll(-1);
    }
  }, [isIdle, pdfHighlighter, pageToScroll]);

  // Find a highlight to scroll to
  useEffect(() => {
    if (pageToScroll < 0) {
      return;
    }

    if (
      typeof highlightIndexInPageToScroll === 'number' &&
      highlightIndexInPageToScroll < 0
    ) {
      return;
    }

    // Skip if didn't search text in page
    const textMatchCountByPage =
      textMatchCountByTermAndPage[searchTermToScroll];
    if (
      !textMatchCountByPage ||
      textMatchCountByPage[pageToScroll] === undefined
    ) {
      return;
    }

    const pageHighlights = highlights
      .filter(
        (h) =>
          h.content.text === searchTermToScroll &&
          h.position.pageNumber === pageToScroll + 1,
      ) // filter highlight in page
      .sort(sortHighlight); // sort by left

    const highlightIndex =
      highlightIndexInPageToScroll === 'first'
        ? 0
        : highlightIndexInPageToScroll === 'last'
          ? pageHighlights.length
          : highlightIndexInPageToScroll;
    const highlight = pageHighlights[highlightIndex];

    setPageToScroll(-1);
    setHighlightIndexInPageToScroll(-1);

    if (highlight) {
      setHighlightToScroll(highlight);
    } else {
      onFocusHighlightChange && onFocusHighlightChange();
    }
  }, [
    pageToScroll,
    searchTermToScroll,
    highlightIndexInPageToScroll,
    textMatchCountByTermAndPage,
    highlights,
    onFocusHighlightChange,
  ]);

  // Scroll to highlight
  useEffect(() => {
    if (!isIdle || !highlightToScroll) {
      return;
    }

    const viewer = pdfHighlighter?.viewer;
    const { boundingRect, pageNumber, usePdfCoordinates } =
      highlightToScroll.position;

    const pageViewport = viewer.getPageView(pageNumber - 1).viewport;
    const scaledRect = scaledToViewport(
      boundingRect,
      pageViewport,
      usePdfCoordinates,
    );

    // TODO scale viewport if the highlight is small
    // Scale viewer if the highlight is small
    // if (autoScale && pdfHighlighter?.viewer && scaledRect.height < MINIMUM_LINE_HEIGHT) {
    // 	const pageView = viewer.getPageView(pageNumber - 1);
    // 	pdfHighlighter.viewer.setScale(pageView.scale * (MINIMUM_LINE_HEIGHT / scaledRect.height));
    // 	pageViewport = viewer.getPageView(pageNumber - 1).viewport;
    // 	scaledRect = scaledToViewport(boundingRect, pageViewport, usePdfCoordinates);
    // }

    const viewerBoundingRect = pdfViewerRef.current?.getBoundingClientRect();
    if (!viewerBoundingRect) {
      return;
    }

    const scrollTop =
      scaledRect.top -
      (viewerBoundingRect?.height || 0) / 2 +
      scaledRect.height / 2;
    const scrollLeft =
      scaledRect.width > viewerBoundingRect.width
        ? scaledRect.left - 10 // when keyword too long, scroll to left of keyword and margin left 10px
        : scaledRect.left -
          (viewerBoundingRect?.width || 0) / 2 +
          scaledRect.width / 2; // scroll to center of keyword

    viewer.scrollPageIntoView({
      destArray: [
        null,
        {
          name: 'XYZ',
        },
        ...pageViewport.convertToPdfPoint(scrollLeft, scrollTop),
      ],
      pageNumber,
    });

    setHighlightToScroll(undefined);
    setFocusingHighlight(highlightToScroll);
    onFocusHighlightChange && onFocusHighlightChange(highlightToScroll);
  }, [isIdle, highlightToScroll, pdfScale]);

  // Update scale
  useEffect(() => {
    isIdle && pdfHighlighter && pdfHighlighter.viewer.setScale(scale);
  }, [isIdle, pdfHighlighter, scale]);

  const onHighlighterRendered = useCallback((highlighter) => {
    setPdfHighlighter(highlighter);
  }, []);

  const _onClick = useCallback(
    (highlightId?: string) => {
      if (!onClick) {
        return;
      }

      const highlight = highlightId
        ? highlightsRef.current.find((h) => h.id === highlightId)
        : undefined;
      onClick(highlight);
    },
    [onClick],
  );

  const isActiveHighlight = useCallback(
    (highlight: PdfHighlight) => {
      return (
        pdfViewMode !== 'preview' &&
        highlight.id === focusingHighlightRef.current?.id
      );
    },
    [pdfViewMode],
  );

  const highlightTransform = useCallback(
    (highlight: PdfHighlight) =>
      createHighlight(highlight, isActiveHighlight(highlight), _onClick),
    [focusingHighlight, _onClick],
  );

  const isRenderByPdf =
    queryStringParameters?.render_by &&
    queryStringParameters?.render_by === 'pdf';
  let containerStyles: CSSProperties = {
    height: '100%',
    position: 'relative',
    width: '100%',
  };

  if (!isRenderByPdf) {
    containerStyles = {
      ...containerStyles,
      backgroundImage: `url(${url})`, // use background image instead of img element to center image
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    };
  }

  return (
    <div
      className={twMerge('d-flex h-100 pdf-viewer', !scrollable && 'no-scroll')}
      onClick={(e) => {
        if (previewFeature && (e.metaKey || e.ctrlKey)) {
          // https://www.w3schools.com/jsref/met_win_open.asp
          // open() is supported in all browsers
          window.open(detailPageUrl, '_blank');
          return;
        }

        _onClick();
      }}
      ref={pdfViewerRef}
    >
      <div style={{ ...containerStyles }}>
        <PdfLoader beforeLoad={<Spinner />} ref={pdfLoader} url={url}>
          {(pdfDocument) => {
            return (
              <CustomizedPdfHighlighter
                highlights={activeHighlights}
                highlightTransform={highlightTransform}
                onSelectionFinished={voidFunc}
                pdfDocument={pdfDocument}
                pdfScaleValue={scale}
                pdfViewMode={pdfViewMode}
                ref={onHighlighterRendered}
                scrollRef={voidFunc}
              />
            );
          }}
        </PdfLoader>
      </div>
    </div>
  );
};
