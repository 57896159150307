import { ApiUrl } from '../constants';
import { httpClient, HttpClientParameters } from './HttpClient';

class AuthService {
  logout(payload: any) {
    const parameters: HttpClientParameters = {
      payload: payload,
      requiresToken: false,
      url: ApiUrl.LOGOUT,
    };
    return httpClient.post(parameters);
  }

  preLogout() {
    const parameters: HttpClientParameters = {
      requiresToken: true,
      url: ApiUrl.PRE_LOGOUT,
    };
    return httpClient.post(parameters);
  }
}

export const authService = new AuthService();
