const delay = (milliseconds: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });

const download = async (url: string, name: string) => {
  const a = document.createElement('a');
  a.download = name;
  a.href = url;
  a.style.display = 'none';
  document.body.append(a);
  a.click();

  // Chrome requires the timeout
  await delay(100);
  a.remove();
};

export async function multiDownload(urls: string[]) {
  if (!urls) {
    throw new Error('`urls` required');
  }

  // Quan said: async way
  // const promises = urls.map((url: string, index: number) => {
  // 	return new Promise((resolve, reject) => {
  // 		setTimeout(() => {
  // 			console.log(`Downloading ${url}...`);
  // 			download(url, url);
  // 			resolve(1);
  // 		}, index * 1000);
  // 	});
  // });

  // await Promise.all(promises);
  // end async way

  // sync way: fire download request per second
  urls.forEach(async (url: string, index: number) => {
    await delay(index * 1000);
    download(url, url);
  });
  // end sync way
}

export default multiDownload;
