export enum PROJECT_CODES {
  BACALHAU = '4014',
  BMC33 = '4042',
  MV31 = '0468',
  UARU = '4103',
}

export type TYPE_PROJECT_CODE =
  | PROJECT_CODES.BACALHAU
  | PROJECT_CODES.BMC33
  | PROJECT_CODES.MV31
  | PROJECT_CODES.UARU;
export type TYPE_PROJECT_CODE_OPTION = {
  label: 'BACALHAU' | 'BMC33' | 'MV31' | 'UARU';
  value: TYPE_PROJECT_CODE;
};
export type TYPE_PROJECT_CODE_OPTIONS = Array<TYPE_PROJECT_CODE_OPTION>;

export const PROJECT_CODE_OBJECTS = {
  [PROJECT_CODES.BACALHAU]: {
    label: 'Bacalhau',
    value: PROJECT_CODES.BACALHAU,
  },
  [PROJECT_CODES.BMC33]: {
    label: 'BM-C-33',
    value: PROJECT_CODES.BMC33,
  },
  [PROJECT_CODES.MV31]: {
    label: 'MV31',
    value: PROJECT_CODES.MV31,
  },
  [PROJECT_CODES.UARU]: {
    label: 'Uaru',
    value: PROJECT_CODES.UARU,
  },
};

export const inValidProjectCode = (projectCode: TYPE_PROJECT_CODE): boolean => {
  return !Object.values(PROJECT_CODES).includes(projectCode);
};

export const PROJECT_CODES_ARRAY: Array<PROJECT_CODES> = Object.values(
  PROJECT_CODES,
) as Array<PROJECT_CODES>;

export const PROJECT_CODES_OBJECT = PROJECT_CODES_ARRAY.reduce(
  (acc: any, curr: PROJECT_CODES) => {
    acc[curr] = curr;
    return acc;
  },
  {},
);
