import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Logo } from '../commons/Logo';

export function Footer() {
  const { t } = useTranslation();

  return (
    <div className="footer">
      <Container fluid>
        <div className="powered-by items-center">
          <span>{t('footer_text_powered_by')}</span>
          <Logo full small />
        </div>

        <a href="https://modec.sharepoint.com/teams/DnA/SitePages/MODEC-Bing-v2.aspx">
          {t('Product guide')}
        </a>
      </Container>
    </div>
  );
}
